import { Box, styled } from '@mui/material'

interface ColorPulseProps {
  pulseColor: string
  disabled?: boolean
}

export default styled(Box)<ColorPulseProps>(({ pulseColor, disabled }) => ({
  animation: disabled ? 'none' : 'pulse 2s ease-out',
  '@keyframes pulse': {
    '0%': {
      color: 'inherit',
    },
    '20%': {
      color: pulseColor,
    },
    '100%': {
      color: 'inherit',
    },
  },
}))
