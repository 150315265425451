import React, { useId } from 'react'
import {
  Box,
  BoxProps,
  Stack,
  Typography,
  TypographyProps,
} from '@mui/material'

interface LabelWithValueItemProps extends BoxProps {
  label: string
  value: string
  bold?: boolean
}

export const LabelWithValueItem: React.FC<LabelWithValueItemProps> = ({
  label,
  value,
  bold = false,
  ...props
}) => {
  const valueId = useId()

  const typographyStyleProps: TypographyProps = bold
    ? {
        variant: 'body1',
        fontWeight: 'bold',
      }
    : {
        variant: 'body2',
        fontWeight: 'medium',
      }

  return (
    <Box {...props}>
      <Stack direction="row" justifyContent="space-between">
        <Typography
          component={'label'}
          htmlFor={valueId}
          {...typographyStyleProps}
        >
          {label}
        </Typography>
        <Typography id={valueId} component="p" {...typographyStyleProps}>
          {value}
        </Typography>
      </Stack>
    </Box>
  )
}

export default LabelWithValueItem
