import React from 'react'
import { useEpiString } from '@Hooks/useEpiStrings'
import { SegmentWrapper } from '@Components/SegmentWrapper'
import {
  Avatar,
  Button,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
  styled,
} from '@mui/material'

const NumberAvatar = styled(Avatar)(({ theme }) => ({
  backgroundColor: theme.palette.primary.light,
  color: theme.palette.primary.dark,
  width: 32,
  height: 32,
  fontSize: 'inherit',
  fontWeight: 500,
}))

interface WelcomeProps {
  onWizardStart: () => void
}

export const Welcome: React.FC<WelcomeProps> = ({ onWizardStart }) => {
  const t = useEpiString()

  const handleStart = () => {
    onWizardStart()
  }

  return (
    <SegmentWrapper>
      <Typography variant="h3" gutterBottom data-testid="welcome-title">
        {t('title')}
      </Typography>
      <Typography variant="body1" component="p" gutterBottom>
        {t('ingress')}
      </Typography>
      <List disablePadding={false} sx={{ mb: (theme) => theme.spacing(6) }}>
        {[
          t('stepDescription1'),
          t('stepDescription2'),
          t('stepDescription3'),
          t('stepDescription4'),
        ].map((step, index) => (
          <ListItem
            key={step}
            disableGutters={false}
            sx={{ p: (theme) => theme.spacing(2, 0) }}
          >
            <ListItemAvatar>
              <NumberAvatar sizes="small">{index + 1}</NumberAvatar>
            </ListItemAvatar>
            <ListItemText
              primary={step}
              primaryTypographyProps={{ fontWeight: 900 }}
            />
          </ListItem>
        ))}
      </List>
      <Button onClick={handleStart}>{t('buttonText')}</Button>
    </SegmentWrapper>
  )
}
