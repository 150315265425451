export const formatCurrency = (value: number): string =>
  value.toLocaleString('sv-SE', {
    style: 'currency',
    currency: 'SEK',
    maximumFractionDigits: 0,
  })

export const formatPercentage = (value: number): string =>
  (value / 100).toLocaleString('sv-SE', {
    style: 'percent',
    maximumFractionDigits: 2,
  })
