import * as Yup from 'yup'
import React from 'react'
import { FormField } from '@Components/FormField'
import { FormikChildProps } from '@Components/FormikWrapper'
import { keys, pick, sum, values } from 'ramda'
import { useEpiString } from '@Hooks/useEpiStrings'
import { useWizard } from '@trr/wizard-library'
import { SegmentWrapper } from '@Components/SegmentWrapper'
import { InfoLink } from '@Components/InfoLink'
import { Box, Stack, Typography } from '@mui/material'
import { ExtendedWizardStep } from '@Components/ExtendedWizardStep/ExtendedWizardStep'
import { useNavigationGtmTracker } from '@Hooks/gtm'

import { formatCurrency } from '../../Utils/Helpers'

export const companyExpensesDefaults = {
  officeExpenses: 0,
  billsExpenses: 0,
  travelExpenses: 0,
  materialExpenses: 0,
  otherCompanyExpenses: 0,
}

export const companyExpensesSchema = Yup.object({
  officeExpenses: Yup.number()
    .integer('Enbart heltal tillåtna')
    .min(0, 'Måste vara minst 0'),
  billsExpenses: Yup.number()
    .integer('Enbart heltal tillåtna')
    .min(0, 'Måste vara minst 0'),
  travelExpenses: Yup.number()
    .integer('Enbart heltal tillåtna')
    .min(0, 'Måste vara minst 0'),
  materialExpenses: Yup.number()
    .integer('Enbart heltal tillåtna')
    .min(0, 'Måste vara minst 0'),
  otherCompanyExpenses: Yup.number()
    .integer('Enbart heltal tillåtna')
    .min(0, 'Måste vara minst 0'),
})

export const CompanyExpenses: React.FC<FormikChildProps> = ({ formik }) => {
  const t = useEpiString()
  const { previousStep, stepCount, activeStep } = useWizard()
  const { trackPreviousStep, trackInvalidFormSubmit } =
    useNavigationGtmTracker()

  const companyExpensesSum = formatCurrency(
    sum(values(pick(keys(companyExpensesDefaults), formik.values)))
  )

  const handleGoBack = () => {
    trackPreviousStep()
    previousStep()
  }

  const handleNextStep = () => {
    formik.handleSubmit()

    if (Object.keys(formik.errors).length > 0) {
      trackInvalidFormSubmit()
    }
  }

  return (
    <SegmentWrapper>
      <ExtendedWizardStep
        progressBar={{ max: stepCount - 1, current: activeStep + 1 }}
        stepInfo={{
          currentStepLabel: t('companyExpensesProgressText'),
          nextStepLabel: t('workHoursProgressText'),
        }}
        nextButton={{
          onClick: handleNextStep,
          text: t('forwardButtonText'),
        }}
        prevButton={{
          onClick: handleGoBack,
          text: t('backButtonText'),
        }}
      >
        <Stack spacing={2}>
          <Box>
            <Typography variant="h5" gutterBottom>
              {t('title')}
            </Typography>
            <Typography variant="subtitle1" gutterBottom>
              {t('ingress')}
            </Typography>
            <InfoLink
              links={[
                {
                  text: t('companyExpensesLink1Text'),
                  url: t('companyExpensesLink1Url'),
                },
                {
                  text: t('companyExpensesLink2Text'),
                  url: t('companyExpensesLink2Url'),
                },
              ]}
              description={t('companyExpensesLinkDescription')}
            />
          </Box>

          <FormField
            label={t('officeExpensesLabel')}
            name={'officeExpenses'}
            formik={formik}
          />
          <FormField
            label={t('billsExpensesLabel')}
            name={'billsExpenses'}
            formik={formik}
          />
          <FormField
            label={t('travelExpensesLabel')}
            placeholder={t('travelExpensesPlaceHolder')}
            name={'travelExpenses'}
            formik={formik}
          />
          <FormField
            label={t('materialExpensesLabel')}
            placeholder={t('materialExpensesPlaceHolder')}
            name={'materialExpenses'}
            formik={formik}
          />
          <FormField
            label={t('otherCompanyExpensesLabel')}
            placeholder={t('otherCompanyExpensesPlaceHolder')}
            name={'otherCompanyExpenses'}
            formik={formik}
          />

          <Stack justifyContent="space-between" direction="row">
            <Typography variant="h6" component="p">
              {t('companyExpensesSummedText')}
            </Typography>
            <Typography variant="h6" component="p">
              {companyExpensesSum}
            </Typography>
          </Stack>
        </Stack>
      </ExtendedWizardStep>
    </SegmentWrapper>
  )
}
