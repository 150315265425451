import React, { FunctionComponent } from 'react'
import { Calculator } from '@Features/Calculator/Calculator'
import { ThemeProvider, createTheme } from '@mui/material'
import { defaultLocale, themeOptions } from '@trr/mui-theme'
import { GTMTracker } from '@trr/gtm-tracking'
import { AppShellDataProps, AppShellDataProvider } from '@trr/app-shell-data'

const App: FunctionComponent<AppShellDataProps> = (appShellData) => {
  const theme = createTheme(themeOptions, defaultLocale)
  return (
    <AppShellDataProvider value={appShellData}>
      <ThemeProvider theme={theme}>
        <GTMTracker mfName="starta-foretag-kalkyler">
          <Calculator />
        </GTMTracker>
      </ThemeProvider>
    </AppShellDataProvider>
  )
}

export default App
