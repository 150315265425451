export enum CompanyType {
  AB,
  EF,
}

// constants that are or can be subject to change
export const ABFee = 0.3142
export const EFFee = 0.45
export const workHoursPerMonthNormalized = 167
export const highEarnerTaxableBreakPoint = 51158
export const highEarnerTax = 0.2

export const evaluateTaxAndFees = (
  wishedIncome: number,
  companyType: CompanyType,
  taxPercentage: number,
  estimatedWorkLoad: number,
  vacationDays: number,
  companyExpenses: number,
  houseHoldExpenses: number
) => {
  const isAF = companyType === CompanyType.AB

  // constants for work and vacation hours
  const vacationHoursPerMonthNormalized = (vacationDays * 8) / 12
  const workHoursPerMonth =
    workHoursPerMonthNormalized * (estimatedWorkLoad / 100) -
    vacationHoursPerMonthNormalized

  // calculate taxes and fees
  const regularTaxPercentage = isAF ? taxPercentage / 100 : EFFee

  const incomeBeforeHigherEarnerTax =
    highEarnerTaxableBreakPoint * (1 - regularTaxPercentage)

  const incomeAboveBreakpointBeforeTaxes = Math.max(
    wishedIncome - incomeBeforeHigherEarnerTax,
    0
  )

  const incomeAboveBreakpointAfterTaxes =
    incomeAboveBreakpointBeforeTaxes /
    (1 - highEarnerTax - regularTaxPercentage)

  const regularTaxAboveBreakpoint =
    incomeAboveBreakpointAfterTaxes * regularTaxPercentage

  const highEarnerTaxAboveBreakpoint =
    incomeAboveBreakpointAfterTaxes * highEarnerTax

  const taxUnderHigherEarnerBreakpoint = incomeAboveBreakpointBeforeTaxes
    ? highEarnerTaxableBreakPoint * regularTaxPercentage +
      regularTaxAboveBreakpoint
    : wishedIncome / (1 - regularTaxPercentage) - wishedIncome

  // finalize sums
  const incomeBeforeTaxes = incomeAboveBreakpointBeforeTaxes
    ? highEarnerTaxableBreakPoint + incomeAboveBreakpointAfterTaxes
    : taxUnderHigherEarnerBreakpoint + wishedIncome

  const taxableSums = {
    normalIncomeTaxToPay: Math.round(taxUnderHigherEarnerBreakpoint),
    highIncomeTaxToPay: Math.round(highEarnerTaxAboveBreakpoint),
  }

  const workProviderFeeToPay = isAF ? incomeBeforeTaxes * ABFee : 0

  const wishedIncomeWithTaxesAndWorkProviderFee = isAF
    ? incomeBeforeTaxes + workProviderFeeToPay
    : incomeBeforeTaxes

  // ------------------------------------------------------------------------------------

  const totalMonthlyNeedInvoice =
    wishedIncomeWithTaxesAndWorkProviderFee + companyExpenses

  const hourlyRateToAchieveIncome = totalMonthlyNeedInvoice / workHoursPerMonth

  const salaryMinusPrivateCosts = wishedIncome - houseHoldExpenses

  return {
    taxableSums: taxableSums,
    wishedIncomeWithPaidTaxes: Math.round(incomeBeforeTaxes),
    workProviderFeeToPay: Math.round(workProviderFeeToPay),
    companyExpensesSum: Math.round(companyExpenses),
    wishedIncomeWithTaxesAndWorkProviderFee: Math.round(
      wishedIncomeWithTaxesAndWorkProviderFee
    ),
    totalMonthlyNeedInvoice: Math.round(totalMonthlyNeedInvoice),
    hourlyRateToAchieveIncome: Math.round(hourlyRateToAchieveIncome),
    salaryMinusPrivateCosts: Math.round(salaryMinusPrivateCosts),
    billedHoursPerMonth: Math.round(workHoursPerMonth),
  }
}
