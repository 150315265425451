import React from 'react'
import { omit } from 'lodash'
import { WizardStep } from '@trr/wizard-library'
import { WizardStepProps } from '@trr/wizard-library/lib/@types/WizardStep/WizardStep'
import { useWizardGtmTracker } from '@Hooks/gtm'

export const ExtendedWizardStep = (props: WizardStepProps) => {
  useWizardGtmTracker()

  return (
    <WizardStep {...omit(props, ['children'])} disableDefaultTracking>
      {props.children}
    </WizardStep>
  )
}
