import { useContext } from 'react'

import { Context } from './Context'

const replaceVariables = (
  text: string,
  variables: { [key: string]: string } = {}
) => {
  let result = text
  Object.keys(variables).forEach((key) => {
    const regex = new RegExp(`{{${key}}}`, 'g')
    result = result.replace(regex, variables[key])
  })
  return result
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useEpiString = () => {
  const values = useContext(Context) ?? {}
  return (key: string, variables?: { [key: string]: string }) =>
    values[key] ? replaceVariables(values[key], variables) : key
}
