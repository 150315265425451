import React, { useEffect } from 'react'
import { FormikProps } from 'formik'
import { TextField } from '@mui/material'

export enum InputType {
  password = 'password',
  number = 'number',
  text = 'text',
}

interface FormFieldProps {
  label: string
  placeholder?: string
  name: string
  formik: FormikProps<unknown>
}

export const FormField: React.FC<FormFieldProps> = ({
  label,
  placeholder,
  name,
  formik,
}) => {
  const { error, touched } = formik.getFieldMeta(name)
  const { value } = formik.getFieldProps(name)

  useEffect(() => {
    formik.setFieldTouched(name, false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    typeof value === 'string' && formik.getFieldHelpers(name).setValue(0)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value])
  return (
    <TextField
      {...formik.getFieldProps(name)}
      label={label}
      placeholder={placeholder}
      id={name}
      type={InputType.number}
      name={name}
      onWheel={(e) => e.target instanceof HTMLElement && e.target.blur()}
      helperText={touched && error ? error : undefined}
      error={Boolean(touched && error)}
      value={value || undefined}
      fullWidth
    />
  )
}
