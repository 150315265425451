import { useWizard } from '@trr/wizard-library'

import { useClickGtmTracker } from './useClickGtmTracker'

export const useNavigationGtmTracker = () => {
  const { trackButtonClick } = useClickGtmTracker()
  const { activeStep } = useWizard()

  const trackInvalidFormSubmit = () => {
    trackButtonClick('button_click_next_invalid', {
      label: `Step ${activeStep}: Nästa`,
    })
  }

  const trackNextStep = (label?: string) => {
    trackButtonClick('button_click_next', {
      label: `Step ${activeStep}: ${label ?? 'Nästa'}`,
      href: `${location.pathname}step: ${activeStep + 1}`,
    })
  }

  const trackPreviousStep = (label?: string) => {
    trackButtonClick('button_click_back', {
      label: `Step ${activeStep}: ${label ?? 'Tillbaka'}`,
      href: `${location.pathname}step: ${activeStep - 1}`,
    })
  }

  return {
    trackNextStep,
    trackPreviousStep,
    trackInvalidFormSubmit,
  }
}
