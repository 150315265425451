import React, { Children, ReactElement } from 'react'
import { Formik, FormikProps } from 'formik'
import { formDataType } from '@Features/Calculator/Calculator'
import { useWizard } from '@trr/wizard-library'
import { useNavigationGtmTracker } from '@Hooks/gtm'

export interface FormikChildProps {
  formik?: FormikProps<unknown>
}

interface FormikWrapperProps extends React.PropsWithChildren {
  defaults: unknown
  schema: unknown
  handleFormData: (data: Partial<formDataType>) => void
}

export const FormikWrapper: React.FC<FormikWrapperProps> = ({
  children,
  defaults,
  schema,
  handleFormData,
}) => {
  const { nextStep } = useWizard()
  const { trackNextStep } = useNavigationGtmTracker()

  const handleFormSubmission = (data: Partial<formDataType>) => {
    handleFormData(data)
    trackNextStep()
    nextStep()
  }
  const handleSaveDataOnValidation = (data: Partial<formDataType>) => {
    handleFormData(data)
  }
  return (
    <Formik
      initialValues={defaults}
      validationSchema={schema}
      onSubmit={handleFormSubmission}
      validate={handleSaveDataOnValidation}
    >
      {(formik) => (
        <>
          <div role="form">
            {Children.map(children, (child, index) => {
              if (child) {
                const form = React.cloneElement(child as ReactElement, {
                  formik,
                })
                return <div key={`formikChild-${index}`}>{form}</div>
              }
              return null
            })}
          </div>
        </>
      )}
    </Formik>
  )
}
