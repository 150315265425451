import * as Yup from 'yup'
import React from 'react'
import { FormField } from '@Components/FormField'
import { FormikChildProps } from '@Components/FormikWrapper'
import { keys, pick, sum, values } from 'ramda'
import { useEpiString } from '@Hooks/useEpiStrings'
import { useWizard } from '@trr/wizard-library'
import { SegmentWrapper } from '@Components/SegmentWrapper'
import { InfoLink } from '@Components/InfoLink'
import { Box, Stack, Typography } from '@mui/material'
import { ExtendedWizardStep } from '@Components/ExtendedWizardStep/ExtendedWizardStep'
import { useNavigationGtmTracker } from '@Hooks/gtm'

import { formatCurrency } from '../../Utils/Helpers'

export const householdExpensesDefaults = {
  houseExpenses: 0,
  foodExpenses: 0,
  personaltravelExpenses: 0,
  otherHouseholdExpenses: 0,
  personalExpenses: 0,
}

export const householdExpensesSchema = Yup.object({
  houseExpenses: Yup.number()
    .integer('Enbart heltal tillåtna')
    .min(0, 'Måste vara minst 0')
    .required(),
  foodExpenses: Yup.number()
    .integer('Enbart heltal tillåtna')
    .min(0, 'Måste vara minst 0'),
  personaltravelExpenses: Yup.number()
    .integer('Enbart heltal tillåtna')
    .min(0, 'Måste vara minst 0'),
  otherHouseholdExpenses: Yup.number()
    .integer('Enbart heltal tillåtna')
    .min(0, 'Måste vara minst 0'),
  personalExpenses: Yup.number()
    .integer('Enbart heltal tillåtna')
    .min(0, 'Måste vara minst 0'),
})

export const HouseholdExpenses: React.FC<FormikChildProps> = ({ formik }) => {
  const householdExpensesSum = formatCurrency(
    sum(values(pick(keys(householdExpensesDefaults), formik.values)))
  )
  const t = useEpiString()
  const { previousStep, stepCount, activeStep } = useWizard()
  const { trackPreviousStep, trackInvalidFormSubmit } =
    useNavigationGtmTracker()

  const handleGoBack = () => {
    trackPreviousStep()
    previousStep()
  }

  const handleNextStep = () => {
    formik.handleSubmit()

    if (Object.keys(formik.errors).length > 0) {
      trackInvalidFormSubmit()
    }
  }

  return (
    <SegmentWrapper>
      <ExtendedWizardStep
        progressBar={{ max: stepCount - 1, current: activeStep + 1 }}
        stepInfo={{
          currentStepLabel: t('householdExpensesProgressText'),
          nextStepLabel: t('companyExpensesProgressText'),
        }}
        nextButton={{
          onClick: handleNextStep,
          text: t('forwardButtonText'),
        }}
        prevButton={{
          onClick: handleGoBack,
          text: t('backButtonText'),
        }}
      >
        <Stack spacing={2}>
          <Box>
            <Typography variant="h5" gutterBottom>
              {t('title')}
            </Typography>
            <Typography variant="subtitle1" gutterBottom>
              {t('ingress')}
            </Typography>
            <InfoLink
              links={[
                {
                  url: t('houseHoldExpensesUrl'),
                  text: t('houseHoldExpensesLinkText'),
                },
              ]}
              description={t('houseHoldExpensesLinkDescription')}
            />
          </Box>

          <FormField
            label={t('houseExpensesLabel')}
            placeholder={t('houseExpensesPlaceHolder')}
            name={'houseExpenses'}
            formik={formik}
          />
          <FormField
            label={t('foodExpensesLabel')}
            placeholder={t('foodExpensesPlaceHolder')}
            name={'foodExpenses'}
            formik={formik}
          />
          <FormField
            label={t('personaltravelExpensesLabel')}
            placeholder={t('personaltravelExpensesPlaceHolder')}
            name={'personaltravelExpenses'}
            formik={formik}
          />
          <FormField
            label={t('otherHouseholdExpensesLabel')}
            placeholder={t('otherHouseholdExpensesPlaceHolder')}
            name={'otherHouseholdExpenses'}
            formik={formik}
          />
          <FormField
            label={t('personalExpensesLabel')}
            placeholder={t('personalExpensesPlaceHolder')}
            name={'personalExpenses'}
            formik={formik}
          />
          <Stack direction="row" justifyContent="space-between">
            <Typography variant="h6" component="p">
              {t('houseHoldExpensesSummedText')}
            </Typography>
            <Typography variant="h6" component="p">
              {householdExpensesSum}
            </Typography>
          </Stack>
        </Stack>
      </ExtendedWizardStep>
    </SegmentWrapper>
  )
}
