import * as Yup from 'yup'
import React, { useEffect, useState } from 'react'
import { FormField } from '@Components/FormField'
import { FormikChildProps } from '@Components/FormikWrapper'
import { workHoursPerMonthNormalized } from '@Utils/Helpers/calculatorHelpers'
import { useEpiString } from '@Hooks/useEpiStrings'
import { useWizard } from '@trr/wizard-library'
import { SegmentWrapper } from '@Components/SegmentWrapper'
import { Box, Stack, Theme, Typography } from '@mui/material'
import { ExtendedWizardStep } from '@Components/ExtendedWizardStep/ExtendedWizardStep'
import { useNavigationGtmTracker } from '@Hooks/gtm'

export const workHoursDefaults = {
  estimatedWorkLoad: 0,
  vacationDays: 0,
}

export const workHoursSchema = Yup.object({
  estimatedWorkLoad: Yup.number()
    .integer('Enbart heltal tillåtna')
    .min(20, 'Måste vara minst 20%')
    .max(200, 'Får inte vara mer än 200%')
    .required(),
  vacationDays: Yup.number()
    .integer('Enbart heltal tillåtna')
    .min(0, 'Får inte vara mindre än 0')
    .max(365, 'Får inte överskrida 365 dagar')
    .required(),
})

export const WorkHours: React.FC<FormikChildProps> = ({ formik }) => {
  const t = useEpiString()
  const { previousStep, stepCount, activeStep } = useWizard()
  const { trackPreviousStep, trackInvalidFormSubmit } =
    useNavigationGtmTracker()
  const values = formik.values as typeof workHoursDefaults
  const workHoursPerMonth = Math.round(
    workHoursPerMonthNormalized * (values.estimatedWorkLoad / 100) -
      (values.vacationDays * 8) / 12
  )
  const [invalidWorkHoursPerMonth, setInvalidWorkHoursPerMonth] =
    useState(false)

  const isTouched =
    formik.getFieldMeta('vacationDays').touched ||
    formik.getFieldMeta('estimatedWorkLoad').touched ||
    values.estimatedWorkLoad > 0 ||
    values.vacationDays > 0

  useEffect(() => {
    workHoursPerMonth <= 0 &&
    formik.getFieldMeta('vacationDays').touched &&
    values.vacationDays > 0
      ? setInvalidWorkHoursPerMonth(true)
      : setInvalidWorkHoursPerMonth(false)
  }, [formik, values.vacationDays, workHoursPerMonth])

  const handleNext = () => {
    if (workHoursPerMonth > 0) {
      formik.handleSubmit()
    } else {
      formik.setFieldTouched('estimatedWorkLoad')
    }
    if (Object.keys(formik.errors).length > 0) {
      trackInvalidFormSubmit()
    }
  }

  const workHoursPerMonthColor = (theme: Theme) => {
    if (!isTouched) return theme.palette.text.primary

    if (workHoursPerMonth <= 0) {
      return theme.palette.error.main
    }
    return theme.palette.primary.main
  }

  const handleGoBack = () => {
    trackPreviousStep()
    previousStep()
  }

  return (
    <SegmentWrapper>
      <ExtendedWizardStep
        progressBar={{ max: stepCount - 1, current: activeStep + 1 }}
        stepInfo={{
          currentStepLabel: t('workHoursProgressText'),
          nextStepLabel: t('resultsProgressText'),
        }}
        nextButton={{
          onClick: handleNext,
          text: t('forwardButtonText'),
        }}
        prevButton={{
          onClick: handleGoBack,
          text: t('backButtonText'),
        }}
      >
        <Stack spacing={2}>
          <Box>
            <Typography variant="h5" gutterBottom>
              {t('title')}
            </Typography>
            <Typography variant="subtitle1">{t('ingress')}</Typography>
          </Box>

          <FormField
            label={t('estimatedWorkLoadLabel')}
            placeholder={t('estimatedWorkLoadPlaceHolder')}
            name={'estimatedWorkLoad'}
            formik={formik}
          />
          <FormField
            label={t('vacationDaysLabel')}
            placeholder={t('vacationDaysPlaceholder')}
            name={'vacationDays'}
            formik={formik}
          />

          <Stack direction="row" justifyContent="space-between" display="flex">
            <Typography variant="h6" component="p">
              {t('estimatedWorkLoadInHours')}
            </Typography>
            <Typography
              variant="h6"
              component="p"
              color={workHoursPerMonthColor}
            >
              {t('workHoursSuffix', { workHours: `${workHoursPerMonth}` })}
            </Typography>
          </Stack>

          {invalidWorkHoursPerMonth && (
            <Typography variant="body2" component="p" color="error">
              {t('workHoursPerMonthError')}
            </Typography>
          )}
        </Stack>
      </ExtendedWizardStep>
    </SegmentWrapper>
  )
}
