import { useEffect } from 'react'
import { useWizard } from '@trr/wizard-library'
import { useTrackVirtualView } from '@trr/gtm-tracking'
import { useFeatureFlag } from '@Hooks/useFeatureFlag'

export const useWizardGtmTracker = () => {
  const stepTrigger = useTrackVirtualView('Företagskalkylatorn')
  const { activeStep } = useWizard()
  const isGtmTrackingEnabled = useFeatureFlag().isGtmTrackingEnabled

  useEffect(() => {
    if (isGtmTrackingEnabled) {
      stepTrigger(`Step: ${activeStep}`)
    }
  }, [stepTrigger, activeStep, isGtmTrackingEnabled])
}
