import React, { Children } from 'react'

export const SegmentWrapper: React.FC<React.PropsWithChildren> = ({
  children,
}) => (
  <div>
    {Children.map(children, (child, index) => {
      if (child) {
        return <div key={`segmentChild-${index}`}>{child}</div>
      }
      return null
    })}
  </div>
)
