import { useIsFeatureEnabled } from '@trr/app-shell-data'

export const useFeatureFlag = () => {
  const testFlagIsEnabled = useIsFeatureEnabled('testFlag')
  const isGtmTrackingEnabled = useIsFeatureEnabled(
    'gtm.enabled.starta-foretag-kalkyler'
  )

  return {
    testFlagIsEnabled,
    isGtmTrackingEnabled,
  }
}
