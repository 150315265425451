import * as Yup from 'yup'
import React from 'react'
import { FormField } from '@Components/FormField'
import { FormikChildProps } from '@Components/FormikWrapper'
import { useEpiString } from '@Hooks/useEpiStrings'
import { useWizard } from '@trr/wizard-library'
import { SegmentWrapper } from '@Components/SegmentWrapper'
import { InfoLink } from '@Components/InfoLink'
import { Box, Stack, Typography } from '@mui/material'
import { ExtendedWizardStep } from '@Components/ExtendedWizardStep/ExtendedWizardStep'
import { useNavigationGtmTracker } from '@Hooks/gtm'

export const taxAndIncomeDefaults = {
  wishedIncome: 0,
  taxPercentage: 32,
}

export const taxAndIncomeSchema = Yup.object({
  wishedIncome: Yup.number()
    .min(1000, 'Måste vara minst 1000 kr')
    .integer('Enbart heltal tillåtna')
    .required(),
  taxPercentage: Yup.number()
    .min(15, 'Måste vara minst 15%')
    .max(50, 'Får inte vara mer än 50%')
    .required(),
})

interface TaxAndIncomeProps {
  onCancel: () => void
}

export const TaxAndIncome: React.FC<FormikChildProps & TaxAndIncomeProps> = ({
  formik,
  onCancel,
}) => {
  const t = useEpiString()
  const { stepCount, activeStep } = useWizard()
  const { trackInvalidFormSubmit } = useNavigationGtmTracker()

  const handleGoBack = () => {
    onCancel()
  }

  const handleNextStep = () => {
    formik.handleSubmit()
    if (Object.keys(formik.errors).length > 0) {
      trackInvalidFormSubmit()
    }
  }

  return (
    <SegmentWrapper>
      <ExtendedWizardStep
        progressBar={{ max: stepCount - 1, current: activeStep + 1 }}
        stepInfo={{
          currentStepLabel: t('taxAndIncomeProgressText'),
          nextStepLabel: t('householdExpensesProgressText'),
        }}
        nextButton={{
          onClick: handleNextStep,
          text: t('buttonText'),
        }}
        prevButton={{
          onClick: handleGoBack,
          text: t('prevButtonText'),
        }}
      >
        <Box mb={2}>
          <Typography variant="h5" gutterBottom>
            {t('title')}
          </Typography>
          <Typography variant="subtitle1" gutterBottom>
            {t('ingress')}
          </Typography>
          <InfoLink
            links={[
              { url: t('taxPercentageUrl'), text: t('taxPercentageLinkText') },
            ]}
            description={t('taxPercentageLinkDescription')}
          />
        </Box>
        <Stack spacing={2}>
          <FormField
            label={t('wishedIncomeLabel')}
            placeholder={t('wishedIncomePlaceHolder')}
            name={'wishedIncome'}
            formik={formik}
          />
          <FormField
            label={t('taxPercentageLabel')}
            placeholder={t('taxPercentagePlaceHolder')}
            name={'taxPercentage'}
            formik={formik}
          />
        </Stack>
      </ExtendedWizardStep>
    </SegmentWrapper>
  )
}
