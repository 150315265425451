/* eslint-disable @typescript-eslint/prefer-nullish-coalescing */
import React, { useState } from 'react'
import { Wizard } from '@trr/wizard-library'
import {
  TaxAndIncome,
  taxAndIncomeDefaults,
  taxAndIncomeSchema,
} from '@Features/Calculations'
import { FormikWrapper } from '@Components/FormikWrapper'
import { Welcome } from '@Features/Welcome'
import { ResultsSummary } from '@Features/Results'
import { EpiStringProvider } from '@Hooks/useEpiStrings'
import { Box } from '@mui/material'
import { useContent } from '@Utils/Content/useContent'

import {
  HouseholdExpenses,
  householdExpensesDefaults,
  householdExpensesSchema,
} from '../Calculations/HouseholdExpenses'
import {
  CompanyExpenses,
  companyExpensesDefaults,
  companyExpensesSchema,
} from '../Calculations/CompanyExpenses'
import {
  WorkHours,
  workHoursDefaults,
  workHoursSchema,
} from '../Calculations/WorkHours'

const formDataDefaults = {
  ...taxAndIncomeDefaults,
  ...householdExpensesDefaults,
  ...companyExpensesDefaults,
  ...workHoursDefaults,
}

export type formDataType = typeof formDataDefaults

export const Calculator: React.FC = () => {
  const {
    companyExpenses,
    workHours,
    results,
    welcome,
    householdExpenses,
    taxAndIncome,
    startaForetagskalkyl,
  } = useContent()

  const [formData, setFormData] = useState<formDataType>(formDataDefaults)
  const [isWizardStarted, setIsWizardStarted] = useState(false)

  const handleFormData = (data: Partial<formDataType>) => {
    setFormData((prev) => ({ ...prev, ...data }))
  }
  return (
    <Box maxWidth={(theme) => theme.breakpoints.values.sm} m="auto">
      {!isWizardStarted ? (
        <EpiStringProvider value={welcome || startaForetagskalkyl?.welcome}>
          <Welcome onWizardStart={() => setIsWizardStarted(true)} />
        </EpiStringProvider>
      ) : (
        <Wizard>
          <EpiStringProvider
            value={taxAndIncome || startaForetagskalkyl?.taxAndIncome}
          >
            <FormikWrapper
              handleFormData={handleFormData}
              schema={taxAndIncomeSchema}
              defaults={formData}
            >
              <TaxAndIncome onCancel={() => setIsWizardStarted(false)} />
            </FormikWrapper>
          </EpiStringProvider>
          <EpiStringProvider
            value={householdExpenses || startaForetagskalkyl?.householdExpenses}
          >
            <FormikWrapper
              handleFormData={handleFormData}
              schema={householdExpensesSchema}
              defaults={formData}
            >
              <HouseholdExpenses />
            </FormikWrapper>
          </EpiStringProvider>
          <EpiStringProvider
            value={companyExpenses || startaForetagskalkyl?.companyExpenses}
          >
            <FormikWrapper
              handleFormData={handleFormData}
              schema={companyExpensesSchema}
              defaults={formData}
            >
              <CompanyExpenses />
            </FormikWrapper>
          </EpiStringProvider>

          <EpiStringProvider
            value={workHours || startaForetagskalkyl?.workHours}
          >
            <FormikWrapper
              handleFormData={handleFormData}
              schema={workHoursSchema}
              defaults={formData}
            >
              <WorkHours />
            </FormikWrapper>
          </EpiStringProvider>
          <EpiStringProvider value={results || startaForetagskalkyl?.results}>
            <ResultsSummary formValues={formData} />
          </EpiStringProvider>
        </Wizard>
      )}
    </Box>
  )
}
