import { useFeatureFlag } from '@Hooks/useFeatureFlag'
import { useTrackCustomClick } from '@trr/gtm-tracking'

export const useClickGtmTracker = () => {
  const trackClick = useTrackCustomClick()
  const isGtmTrackingEnabled = useFeatureFlag().isGtmTrackingEnabled

  const trackButtonClick = (
    identifier: string,
    options: { label: string; href?: string }
  ) => {
    if (isGtmTrackingEnabled) {
      trackClick(identifier, { label: options?.label, href: options?.href })
    }
  }

  return {
    trackButtonClick,
  }
}
