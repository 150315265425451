import React from 'react'
import { Box, Stack, Typography, alpha, styled } from '@mui/material'

interface BlobTextProps {
  heading: string
  description: string
  color: string
  textColor: string
}

const BlobWrapper = styled(Stack)<{ textColor: string }>(({ textColor }) => ({
  width: '245px',
  height: '184px',
  color: textColor,
}))

export const BlobText: React.FC<BlobTextProps> = ({
  heading,
  description,
  color,
  textColor = 'fffff',
}) => {
  const lightColor = alpha(color, 0.1)
  return (
    <BlobWrapper
      textColor={textColor}
      spacing={0}
      alignItems="center"
      justifyContent="center"
      display="flex"
    >
      <Box height={'inherit'} width={'inherit'} position={'absolute'}>
        <svg
          height={'100%'}
          width={'100%'}
          role="img"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 294 187"
        >
          <g>
            <path
              opacity="0.998"
              fill={color}
              d="M 98.5,-0.5 C 113.5,-0.5 128.5,-0.5 143.5,-0.5C 148.306,0.592618 153.306,1.25928 158.5,1.5C 188.302,7.60175 215.635,19.4351 240.5,37C 262.245,53.2285 276.579,74.3951 283.5,100.5C 287.16,119.907 283.326,137.573 272,153.5C 266.502,158.991 260.002,162.825 252.5,165C 244.27,166.877 235.937,167.71 227.5,167.5C 219.453,167.277 211.453,167.777 203.5,169C 189.449,172.077 175.449,175.411 161.5,179C 153.032,180.265 144.699,181.431 136.5,182.5C 93.7301,183.647 55.0635,171.814 20.5,147C 10.8593,138.889 3.85932,129.055 -0.5,117.5C -0.5,111.833 -0.5,106.167 -0.5,100.5C 1.70613,94.4215 4.70613,88.7548 8.5,83.5C 26.9164,60.3561 45.5831,37.3561 64.5,14.5C 74.1852,5.65512 85.5186,0.655121 98.5,-0.5 Z"
            />
          </g>
          <g>
            <path
              opacity="0.966"
              fill={lightColor}
              d="M 158.5,1.5 C 199.417,1.47183 236.417,13.3052 269.5,37C 279.502,44.8652 286.669,54.6985 291,66.5C 294.039,79.1465 291.539,90.4799 283.5,100.5C 276.579,74.3951 262.245,53.2285 240.5,37C 215.635,19.4351 188.302,7.60175 158.5,1.5 Z"
            />
          </g>
          <g>
            <path
              opacity="0.987"
              fill={lightColor}
              d="M 64.5,14.5 C 45.5831,37.3561 26.9164,60.3561 8.5,83.5C 4.37263,63.9713 7.87263,45.9713 19,29.5C 23.9253,24.2872 29.7586,20.4539 36.5,18C 45.6998,15.7741 55.0331,14.6074 64.5,14.5 Z"
            />
          </g>
          <g>
            <path
              opacity="0.941"
              fill={lightColor}
              d="M 227.5,167.5 C 220.61,175.453 211.943,180.62 201.5,183C 179.816,186.029 158.15,185.862 136.5,182.5C 144.699,181.431 153.032,180.265 161.5,179C 175.449,175.411 189.449,172.077 203.5,169C 211.453,167.777 219.453,167.277 227.5,167.5 Z"
            />
          </g>
        </svg>
      </Box>
      <Typography component="p" variant="h4" position="relative" zIndex="2">
        {heading}
      </Typography>
      <Typography variant="body1" position="relative" zIndex="2">
        {description}
      </Typography>
    </BlobWrapper>
  )
}
