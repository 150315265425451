import React from 'react'
import { Link as MuiLink, Typography } from '@mui/material'
import { Link } from 'react-router-dom'

interface InfoLinkProps {
  description: string
  links: { url: string; text: string }[]
}

export const InfoLink: React.FC<InfoLinkProps> = ({ description, links }) => (
  <>
    <Typography variant="body1">{description}</Typography>
    {links.map(({ url, text }, i) => (
      <MuiLink
        component={Link}
        target="_blank"
        to={{ pathname: url }}
        underline="hover"
        variant="body1"
        display="block"
        key={i}
      >
        {text}
      </MuiLink>
    ))}
  </>
)
